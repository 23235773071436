"use client";

import { PropsWithChildren } from "react";
import { Toaster } from "sonner";
import { QueryProvider } from "./QueryProvider";
import { AppStoreProvider } from "./AppStoreProvider";

export function Providers({ children }: PropsWithChildren) {
  return (
    <AppStoreProvider>
      <QueryProvider>{children}</QueryProvider>
      <Toaster position="bottom-center" toastOptions={{ duration: 2500 }} />
    </AppStoreProvider>
  );
}
