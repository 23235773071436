import { IService } from "@/utils/types";
import { Alpha2Code } from "@/lib/i18n-iso-countries";

import Feature1Img from "@/images/feature-1.webp";
import Feature2Img from "@/images/feature-2.webp";
import Feature3Img from "@/images/feature-3.webp";

import GuranteeCard1 from "@/images/gurantee-card-1.webp";
import GuranteeCard2 from "@/images/gurantee-card-2.webp";
import GuranteeCard3 from "@/images/gurantee-card-3.webp";

import BookingStep2 from "@/images/booking-step-2.webp";
import BookingStep3 from "@/images/booking-step-3.webp";
import BookingStep4 from "@/images/booking-step-4.webp";

export const isServer = () => typeof window === "undefined" || "Deno" in window;
export const isDev = () => process.env.NODE_ENV === "development";

export const CURRENCY = process.env.NEXT_PUBLIC_PAYMOB_CURRENCY;
export const VAT_RATE = 1.14; // 14% VAT
export const VAT_PERCENTAGE = Math.round((VAT_RATE - 1) * 100); // 14
export const LOCATE2U_BRAND_ID = 76013;

// TODO: currenly skygo has only egypt team
// get regionId from https://app.locate2u.com/team/team-regions
export const LOCATE2U_REGIONS = [{ regionId: 25517, regionName: "Egypt", timezone: "Egypt", countryCode: "EG" }];

export const TWENTY_FOUR_HOURS_IN_MS = 1000 * 60 * 60 * 24;
export const ONE_HOUR_IN_MS = 1000 * 60 * 60;
export const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;
export const TEN_MINUTES_IN_MS = 1000 * 60 * 10;

export const DEFAULT_SERVICES: IService[] = [
  {
    type: "plan",
    serviceId: 76051,
    title: "Luxury",
    price: 0,
    description: "A personalized check-in experience at your doorstep.",
    limitFor: "per person",
    bullets: [
      "Home check-in",
      "Luggage handling (weighing, tagging, and pickup)",
      "Boarding pass printing",
      "Luggage loading onto the flight",
      "Verify to fly",
      "2 check-in bags per passenger",
    ],
  },
  {
    type: "plan",
    serviceId: 76049,
    title: "Family Luxury",
    description: "A personalized check-in experience at your doorstep.",
    price: 0,
    limitFor: "per family",
    isPopular: true,
    bullets: [
      "Home check-in",
      "Luggage handling (weighing, tagging, and pickup)",
      "Boarding pass printing",
      "Luggage loading onto the flight",
      "Verify to fly",
      "4 Passengers – 8 Bags (2 check-in bags per passenger)",
    ],
  },
  {
    type: "plan",
    serviceId: 76050,
    title: "Elite",
    description: "Elevate Your Journey: effortless elegance, unmatched ease",
    price: 0,
    limitFor: "per family",
    bullets: [
      "Home check-in",
      "Luggage handling (weighing, tagging, and pickup)",
      "Boarding pass printing",
      "Luggage loading onto the flight",
      "Verify to fly",
      "4 Passengers – 8 Bags (2 check-in bags per passenger)",
      "Exclusive Meet & Greet service at the airport",
      "Fast track through immigration and security",
      "Chauffeur service",
      "VIP experience till the boarding gate",
    ],
  },
  {
    type: "passenger",
    serviceId: 76046,
    title: "Additional Passengers",
    description: "On top of the selected package",
    price: 0,
  },
  {
    type: "chauffeur",
    serviceId: 76053,
    title: "Chauffeur",
    description: "Have someone pick you up 3 hours before your flight time.",
    price: 0,
  },
  {
    type: "storage",
    serviceId: 76052,
    title: "Additional Luggage",
    description: "Any additional bags on top of your selected package.",
    price: 0,
  },
  {
    type: "wrap",
    serviceId: 76087,
    title: "Luggage Wrapping",
    description: "Luggage wrapping at the airport per piece.",
    price: 0,
  },
];

export const FAQS = [
  {
    title: "Is the team vetted and authorized to conduct the service?",
    description:
      "Our team consists of airport and airline personnel who are authorized to verify documents and check in bags.",
    open: true,
  },
  {
    title: "What happens to my bags after they are tagged and checked in?",
    description:
      "Your bags will be securely collected and delivered to the airport as if you were at the check-in counter. This means that the bags will be loaded onto the airport luggage carousel and then onto the plane.",
  },
  {
    title: "How do you guarantee that no one has tampered with the bags?",
    description:
      "Our team comprises vetted professionals approved by the authorities to conduct the service. Our vans are equipped with CCTV that provides live access to the authorities, and all bags are secured with a one-time lock that proves no one has tampered with the bags during collection until they are loaded onto the luggage carousel.",
  },
  {
    title: "What is the pricing of the service?",
    description:
      "The price of the service is based on the number of passengers, and we have a special package for families. In addition to that, you can add a chauffeur service and/or meet & greet with Ahlan to enjoy the elite experience.",
  },
];

export const FEATURES = [
  {
    title: "Private check-in experience at your place",
    description: "Verified to fly, handover your bags, collect your boarding passes & luggage tags on the spot.",
    image: Feature1Img,
  },
  {
    title: "Your bags are secured with SKYGO",
    description:
      "Your bags will be locked with a one-time lock 🔐 with a unique serial number and a live tracking link",
    image: Feature2Img,
  },
  {
    title: "Flexible scheduling",
    description:
      "Our flexible scheduling allow our customers to book the service at any time of convenience and change it as long as it's within the allowed time frame.",
    image: Feature3Img,
  },
];

export const GUARANTEES = [
  {
    title: "Verified to Fly with No Surprises",
    description: "If any unexpected issues arise, we will provide you with a complimentary service as a refund.",
    image: GuranteeCard1,
  },
  {
    title: "Stress-Free Travel",
    description: "We guarantee a hassle-free journey as your bags are collected and loaded onto the plane.",
    image: GuranteeCard2,
  },
  {
    title: "Guaranteed Customer Satisfaction",
    description:
      "Receive your boarding pass and luggage tags directly from us, so you can skip the check-in queue and experience a different way to travel.",
    image: GuranteeCard3,
  },
];

export const STEPS = [
  {
    media: "/videos/video.mp4",
    title: "Book your mobile airport check-in service",
    description: "Select the service time and date, after providing the flight details.",
    active: false,
    percent: 0,
  },
  {
    media: BookingStep2,
    title: "Receive booking confirmation & tracking link",
    description:
      "With the provided link, you can track our team and your bags from the moment they leave your home until they are loaded onto your flight.",
    active: false,
    percent: 0,
  },
  {
    media: BookingStep3,
    title: "Bags drop & boarding pass collection",
    description:
      "Our airport team will arrive as scheduled, weigh your bags and seal them for security, and issue your boarding pass and collect your bags.",
    active: false,
    percent: 0,
  },
  {
    media: BookingStep4,
    title: "Enjoy a pleasant experience at the airport without queues",
    description: "Once you reach the airport head directly to the boarding gate without queues with our Elite package.",
    active: false,
    percent: 0,
  },
];

export const countriesNameMap: Partial<Record<Alpha2Code, string>> = {
  US: "United States",
  TR: "Turkey",
  IR: "Iran",
};

export const disallowedCountries: Partial<Record<Alpha2Code, boolean>> = {
  // AF: true, // Afghanistan
  // LY: true, // Libya
  // NG: true, // Nigeria
  // YE: true, // Yemen
};
