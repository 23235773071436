import { AsyncStorage } from "@tanstack/react-query-persist-client";
import { kv } from "@vercel/kv";

export class KVStorage implements AsyncStorage {
  static getInstance() {
    return new KVStorage();
  }

  getItem(key: string): Promise<string | null> {
    return kv.get(key);
  }

  setItem(key: string, value: string): Promise<any | null> {
    return kv.set(key, value);
  }

  async removeItem(key: string): Promise<void> {
    await kv.del(key);
  }
}
